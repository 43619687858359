import { css } from '@emotion/react';
import normalize from 'emotion-normalize';
import Typography from './typography';
import Fonts from './fonts';
import Grid from './grid';
import Scaffolding from './scaffolding';
import Animations from './animations';
import Buttons from './buttons';
import Forms from './forms';
import Loader from './loader';
import Override from './override';
import Sections from './sections';

export default css`
  ${normalize}
  ${Fonts}
  ${Loader}
  ${Animations}
  ${Scaffolding}
  ${Typography}
  ${Grid}
  ${Buttons}
  ${Forms}
  ${Override}
  ${Sections}
`;
