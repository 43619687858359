import { BREAKPOINT, COLUMNS, FONTS, GUTTER, GRID } from './variables';

// create a js type mapGet function
export function getBreakpoint(key: string): string {
  if (!BREAKPOINT[key]) {
    return null;
  }
  return BREAKPOINT[key];
}

export function getGrid(key: string): string {
  return GRID[key];
}
export function getGutter(
  key: string,
  calculateCallback: (size: number) => number = (size) => size,
): string | null {
  if (!GUTTER[key]) {
    return null;
  }

  const calc: number = calculateCallback(GUTTER[key]);

  return `${calc}px`;
}

export function getColumn(
  key: string,
  calculateCallback: (size: number) => number = (size) => size,
): number {
  if (!COLUMNS[key]) {
    return null;
  }

  const calc: number = calculateCallback(COLUMNS[key]);

  return calc;
}

export function rem(_fontSize: number, _base = FONTS.sizeBase): string {
  return `${_fontSize / _base}rem`;
}
