import { css } from '@emotion/react';

const Scaffolding = css`
  /**
 * Scaffolding
 */
  * {
    box-sizing: border-box;
    outline: none;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: unset;
  }

  body {
    :not(:first-of-type) {
      margin-top: 0px;
    }
    .content,
    footer {
      filter: none;
      transition: 0.3s filter linear;
    }

    &.blur {
      overflow: hidden;

      .content,
      footer {
        filter: none;
        filter: blur(10px);
        // A bug is present when blur is rendered in safari (mobile phones only).
        // On top of the original blur effect, a backdrop-filter is activated.
        // Backdrop-filter does not work on some browsers, but it's applicable
        // in safari.
        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          backdrop-filter: blur(10px);
        }
      }
    }
  }
`;

export default Scaffolding;
