import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import App from '@/components/app/App';

const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <App>{element}</App>
);

export default WrapRootElement;
