import WrapRootElement from './src/gatsby/WrapRootElement';

export const wrapRootElement = WrapRootElement;

export const onClientEntry = () => {
  /**
   * This snippet lazy loads the video on hero page
   */
  window.onload = () => {
    var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

    if ('IntersectionObserver' in window) {
      var lazyVideoObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === 'string' &&
                videoSource.tagName === 'SOURCE'
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove('lazy');
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  };
};
