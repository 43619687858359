/**
 * Fonts
 */
export const FONT_FAMILY = {
  gtEesti: `GT Eesti`,
  gtEestiProDisplay: `GT Eesti Pro Display`,
  fallback: `Helvetica, Arial, sans-serif`,
};

export const FONTS = {
  gtEesti: `${FONT_FAMILY.gtEesti}, ${FONT_FAMILY.fallback}`,
  gtEestiProDisplay: `${FONT_FAMILY.gtEestiProDisplay}, ${FONT_FAMILY.fallback}`,
  sizeBase: 16,
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
};

export const COLOR = {
  red1: `#CE815A`,
  green1: `#224851`,
  whiteSmoke: `#f1f4f4`,
  whiteSmoke1: `#f2f2f2`,
  outerSpace: `#222c2d`,
  spaceLight: `#3a4849`,
  darkGreen: `#222c2d`,
  woodSmoke: `#0a0d0d`,
  woodSmoke1: `#0a0d0d`,
  woodSmoke2: `#3b3d3d`,
  woodSmoke3: `#6a6b6b`,
  woodSmoke4: `#858686`,
  woodSmoke5: `#e7e7e7`,
  palmLeaf: `#14360e`,
  sycamore: `#9e883d`,
  whiteFade: `#fafafa`,
  eden: ` #0f5450`,
  edenLight: `#1eaaa2`,
  sandyHand: `#ee9c73`,
  sandyHandLight: `#f4bca0`,
  white: `#fff`,
  outline: `#979797`,
  black: `#000000`,
  red: `#FF0000`,
};

export const COLUMNS = {
  mobile: 2,
  tablet: 2,
  desktop: 4,
  large: 4,
  wide: 4,
};

export const GRID = {
  mobile: `100%`,
  tablet: `608px`,
  desktop: `1040px`,
  large: `1216px`,
  wide: `1584px`,
};

export const BREAKPOINT = {
  mobile: `0px`,
  phablet: `576px`,
  tablet: `768px`,
  desktop: `1200px`,
  large: `1440px`,
  wide: `1920px`,
};

export const GUTTER = {
  mobile: 24,
  tablet: 40,
  desktop: 80,
  large: 80,
  wide: 80,
};
