import { css } from '@emotion/react';
import { rem } from './functions';
import { COLOR, FONTS } from './variables';

const Forms = css`
  input,
  textarea {
    width: 100%;
    border: 1px solid ${COLOR.woodSmoke4};
    border-radius: 4px;
    font-size: 16px;
    padding: 15px;
    color: ${COLOR.woodSmoke1};
    margin-bottom: 16px;
  }

  input {
    min-height: 56px;
  }

  textarea {
    min-height: 236px;
    resize: none;

    &::-webkit-scrollbar {
      border-left: 1px solid ${COLOR.woodSmoke4};
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${COLOR.woodSmoke4};
    }
  }

  label {
    font-family: ${FONTS.gtEestiProDisplay};
    font-size: ${rem(14)};
    line-height: 22px;
    font-weight: ${FONTS.light};
    color: ${COLOR.woodSmoke2};
    display: block;
    margin-bottom: 8px;

    &.attachment {
      cursor: pointer;
      width: fit-content;
      width: -moz-fit-content;

      &::before {
        content: '';
        display: inline-block;
        background-image: url('/assets/images/paperclip.svg');
        background-repeat: no-repeat;
        width: 11px;
        height: 24px;
        vertical-align: middle;
        margin-right: 9px;
      }

      + input[type='file'] {
        display: inline-block;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        line-height: 0;
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
      }
    }
  }
`;

export default Forms;
