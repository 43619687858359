import { css, keyframes } from '@emotion/react';

export const bounce = keyframes`
  0% {
    top: 0px;
  }
  100% {
    top: -15px;
  }
`;
export const fadeInUp = keyframes`
  from {
      opacity: 0;
      transform: translate3d(0, 10%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const spin = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Animations = css`
  .animated {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0ms;
    -moz-animation-delay: 0ms;
    animation-delay: 0ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    opacity: 0;

    &.animate__delay-1s {
      -webkit-animation-delay: 1s;
      -moz-animation-delay: 1s;
      animation-delay: 1s;
    }
  }

  .fadeInUp {
    -webkit-animation-name: ${fadeInUp};
    -moz-animation-name: ${fadeInUp};
    animation-name: ${fadeInUp};
    -webkit-animation-duration: 750ms;
    -moz-animation-duration: 750ms;
    animation-duration: 750ms;
    -webkit-transition-duration: 750ms;
    -moz-transition-duration: 750ms;
    transition-duration: 750ms;
  }

  .card {
    div.gatsby-image-wrapper img {
      -webkit-transition: transform 0.2s;
      -moz-transition: transform 0.2s;
      transition: transform 0.2s;
    }

    &:not(.no-animate):hover {
      img {
        transform: scale(1.1);
      }
    }

    > a:not(.link),
    > a:not(.link):hover:not(:focus-visible),
    > a:not(.link):active {
      text-decoration: none;
      -webkit-text-decoration: none;
      -moz-text-decoration: none;
      transition: none;
      -webkit-transition: none;
      -moz-transition: none;
      border-bottom: none;
    }
  }
`;

export default Animations;
