import isUndefined from 'lodash/isUndefined';

import { AppAction, AppState } from './types';

/**
 * App reducer
 */
export default function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case `SetPageMeta`:
      return {
        ...state,
        page: {
          ...state.page,
          meta: action.payload,
        },
      };
    case `SetPageOptions`:
      return {
        ...state,
        page: {
          ...state.page,
          options: {
            hideFooter: undefined,
            hideHeader: undefined,
            ...action.payload,
          },
        },
      };
    case `ToggleHeaderFocus`:
      return {
        ...state,
        header: {
          ...state.header,
          focused: isUndefined(action.payload)
            ? !state.header.focused
            : action.payload,
        },
      };
    case `ToggleMenuVisibility`:
      return {
        ...state,
        menu: {
          ...state.menu,
          visible: isUndefined(action.payload)
            ? !state.menu.visible
            : action.payload,
        },
      };
    default:
      return state;
  }
}
