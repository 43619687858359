import styled from '@emotion/styled';
import { COLOR } from '@/app/common/style/variables';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.page-home {
    .contentful-section-header {
      position: absolute;
      width: 100%;
      top: 0;
    }

    .contentful-section-logo a img {
      filter: invert(1);
    }

    .contentful-section-links ul li a {
      color: ${COLOR.white};

      &:after {
        border-bottom: 1px ${COLOR.white} solid;
      }
    }
  }
`;
