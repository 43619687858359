import { css } from '@emotion/react';
import { COLOR } from './variables';

const Loader = css`
  .ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 10px;

    div {
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${COLOR.sandyHandLight};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-of-type(1) {
      left: 6px;
      animation: ellipsis1 0.6s infinite;
    }
    div:nth-of-type(2) {
      left: 6px;
      animation: ellipsis2 0.6s infinite;
    }
    div:nth-of-type(3) {
      left: 26px;
      animation: ellipsis2 0.6s infinite;
    }
    div:nth-of-type(4) {
      left: 45px;
      animation: ellipsis3 0.6s infinite;
    }
  }

  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`;

export default Loader;
