import { css } from '@emotion/react';
import { FONTS, FONT_FAMILY } from './variables';

const Fonts = css`
  @font-face {
    font-family: ${FONT_FAMILY.gtEesti};
    font-weight: ${FONTS.regular};
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/gt-eesti-text/regular.eot?#iefix')
        format('embedded-opentype'),
      url('/assets/fonts/gt-eesti-text/regular.woff2') format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/gt-eesti-text/regular.woff') format('woff'),
      /* Pretty Modern Browsers */
        url('/assets/fonts/gt-eesti-text/regular.otf') format('opentype'),
      url('/assets/fonts/gt-eesti-text/regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/gt-eesti-text/regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: ${FONT_FAMILY.gtEestiProDisplay};
    font-weight: ${FONTS.regular};
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/gt-eesti-pro-display/regular.eot?#iefix')
        format('embedded-opentype'),
      url('/assets/fonts/gt-eesti-pro-display/regular.woff2') format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display/regular.woff') format('woff'),
      /* Pretty Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display/regular.otf') format('opentype'),
      url('/assets/fonts/gt-eesti-pro-display/regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/gt-eesti-pro-display/regular.svg#GTEestiProDisplay-Regular')
        format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: ${FONT_FAMILY.gtEestiProDisplay}; //$font-family-gt-eesti-pro-display;
    font-weight: ${FONTS.thin}; //$font-thin;
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/gt-eesti-pro-display-thin/thin.eot?#iefix')
        format('embedded-opentype'),
      url('/assets/fonts/gt-eesti-pro-display-thin/thin.woff2') format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-thin/thin.woff') format('woff'),
      /* Pretty Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-thin/thin.otf')
        format('opentype'),
      url('/assets/fonts/gt-eesti-pro-display-thin/thin.ttf') format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/gt-eesti-pro-display-thin/thin.svg#GTEestiProDisplay-Thin')
        format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: ${FONT_FAMILY.gtEestiProDisplay};
    font-weight: ${FONTS.light};
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/gt-eesti-pro-display-light/light.eot?#iefix')
        format('embedded-opentype'),
      url('/assets/fonts/gt-eesti-pro-display-light/light.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-light/light.woff')
        format('woff'),
      /* Pretty Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-light/light.otf')
        format('opentype'),
      url('/assets/fonts/gt-eesti-pro-display-light/light.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/gt-eesti-pro-display-light/light.svg#GTEestiProDisplay-Light')
        format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: ${FONT_FAMILY.gtEestiProDisplay};
    font-weight: ${FONTS.medium};
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/gt-eesti-pro-display-medium/medium.eot?#iefix')
        format('embedded-opentype'),
      url('/assets/fonts/gt-eesti-pro-display-medium/medium.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-medium/medium.woff')
        format('woff'),
      /* Pretty Modern Browsers */
        url('/assets/fonts/gt-eesti-pro-display-medium/medium.otf')
        format('opentype'),
      url('/assets/fonts/gt-eesti-pro-display-medium/medium.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/gt-eesti-pro-display-medium/medium.svg#GTEestiProDisplay-Medium')
        format('svg'); /* Legacy iOS */
  }
`;

export default Fonts;
