import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

import { ElementAttributes } from '@/utils/contentful';

const style = css`
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition-property: opacity, transform;
  transition-duration: 0.8s;
  transition-timing-function: ease;

  &.reveal-in {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const elements = {
  h1: styled.h1`
    ${style}
  `,
  h2: styled.h2`
    ${style}
  `,
  div: styled.div`
    ${style}
  `,
  section: styled.section`
    ${style}
  `,
  li: styled.li`
    ${style}
  `,
};

interface RevealProps extends ElementAttributes {
  children: ReactNode;
  element?: keyof typeof elements;
  threshold?: number;
}

/**
 * Reveal component on scroll
 */
const Reveal: FC<RevealProps> = ({
  children,
  className,
  element = `div`,
  id,
  threshold = 0.25,
}) => {
  const { inView, ref } = useInView({
    threshold,
    triggerOnce: true,
  });
  const Element = elements[element];

  return (
    <Element
      ref={ref}
      className={clsx(className, {
        'reveal-in': inView,
      })}
      id={id}
    >
      {children}
    </Element>
  );
};

export default Reveal;
