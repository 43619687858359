import { createContextSet } from '@/utils/context';
import reducer from './reducer';
import { AppAction, AppState } from './types';

export const defaultState: AppState = {
  header: {
    focused: true,
  },
  menu: {
    visible: false,
  },
  page: {
    options: {
      hideFooter: false,
      hideHeader: false,
    },
  },
};

export const { Context, Provider, useContext } = createContextSet<
  AppAction,
  AppState
>(reducer, defaultState);
