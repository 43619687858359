import fromPairs from 'lodash/fromPairs';
import { ContentfulKeyValuePair, ContentfulOption } from '@/types';

export const attributeKeys = [`className`, `id`];
export const imageAttributeKeys = [`width`, `height`, `title`, `alt`];

export interface ElementAttributes {
  className?: string;
  id?: string;
  role?: string;
}

export interface PageOptions {
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export interface SectionOptions {
  noContainer?: boolean;
}

/**
 * Get attributes
 */
export function getElementAttributes(
  attributes: ContentfulKeyValuePair[] = [],
  keys: string[] = [],
): ElementAttributes {
  return fromPairs(
    (attributes || [])
      .filter(({ key }) => [...attributeKeys, ...keys].includes(key))
      .map(({ key, value }) => [key, value]),
  );
}

/**
 * Get page options
 */
export function getPageOptions(options: ContentfulOption[] = []): PageOptions {
  const activeOptions = (options || []).map(({ value }) => value);
  return {
    hideFooter: activeOptions.includes(`hide-footer`),
    hideHeader: activeOptions.includes(`hide-header`),
  };
}

/**
 * Get section options
 */
export function getSectionOptions(
  options: ContentfulOption[] = [],
): SectionOptions {
  const activeOptions = (options || []).map(({ value }) => value);
  return {
    noContainer: activeOptions.includes(`no-container`),
  };
}
