import { css } from '@emotion/react';

import { desktopDown } from './mixins';

const Sections = css`
  .contentful-section-divider + .contentful-section-cards {
    padding-top: 50px;
  }

  .contentful-section-divider + .contentful-section-gallery {
    padding-top: 66px;
  }

  .contentful-section-logo {
    position: relative;
    z-index: 1;
  }

  ${desktopDown(css`
    .contentful-section-divider + .contentful-section-cards {
      padding-top: 20px;
    }

    .contentful-section-divider + .contentful-section-gallery {
      padding-top: 20px;
    }
  `)}
`;

export default Sections;
