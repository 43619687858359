import React, { FC, ReactNode } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';
import kebabCase from 'lodash/kebabCase';

import Reveal from '@/components/reveal/Reveal';
import { ContentfulCard } from '@/components/card/Card';
import { ButtonsSection } from '@/components/sections/buttons/Buttons';
import { CardsSection } from '@/components/sections/cards/Cards';
import { ContentSection } from '@/components/sections/content/Content';
import { DividerSection } from '@/components/sections/divider/Divider';
import { FooterSection } from '@/components/sections/footer/Footer';
import { GallerySection } from '@/components/sections/gallery/Gallery';
import { GroupSection } from '@/components/sections/group/Group';
import { HeaderSection } from '@/components/sections/header/Header';
import { HeadlineSection } from '@/components/sections/headline/Headline';
import { HeroSection } from '@/components/sections/hero/Hero';
import { LinksSection } from '@/components/sections/links/Links';
import { LogoSection } from '@/components/sections/logo/Logo';
import { getElementAttributes, getSectionOptions } from '@/utils/contentful';
import { ContentfulSection } from './types.d';

/**
 * Loadable components
 */
const Card = loadable(() => import(`@/components/card/Card`));
const Buttons = loadable(() => import(`@/components/sections/buttons/Buttons`));
const Cards = loadable(() => import(`@/components/sections/cards/Cards`));
const Content = loadable(() => import(`@/components/sections/content/Content`));
const Divider = loadable(() => import(`@/components/sections/divider/Divider`));
const Footer = loadable(() => import(`@/components/sections/footer/Footer`));
const Gallery = loadable(() => import(`@/components/sections/gallery/Gallery`));
const Group = loadable(() => import(`@/components/sections/group/Group`));
const Header = loadable(() => import(`@/components/sections/header/Header`));
const Headline = loadable(
  () => import(`@/components/sections/headline/Headline`),
);
const Hero = loadable(() => import(`@/components/sections/hero/Hero`));
const Links = loadable(() => import(`@/components/sections/links/Links`));
const Logo = loadable(() => import(`@/components/sections/logo/Logo`));

interface ContentfulSectionProps extends ContentfulSection {
  children?: ReactNode;
  ignoreContainer?: boolean;
}

interface SectionsProps
  extends Omit<
    ContentfulSectionProps,
    'id' | 'options' | 'attributes' | 'sys'
  > {
  sections: ContentfulSection[];
}

const noneRevealable = [
  `section-content`,
  `section-footer`,
  `section-group`,
  `section-header`,
];

/**
 * Page Section
 */
const Section: FC<ContentfulSectionProps> = ({
  children,
  ignoreContainer = false,
  ...section
}) => {
  const { attributes = [], options, sys } = section;
  const { noContainer } = getSectionOptions(options);
  const type = kebabCase(sys?.contentType.sys.id);

  const renderedSection = ((): false | ReactNode => {
    switch (type) {
      case `card`:
        return <Card {...(section as ContentfulCard)} />;
      case `section-buttons`:
        return <Buttons {...(section as ButtonsSection)} />;
      case `section-cards`:
        return <Cards {...(section as CardsSection)} />;
      case `section-content`:
        return <Content {...(section as ContentSection)}>{children}</Content>;
      case `section-divider`:
        return <Divider {...(section as DividerSection)} />;
      case `section-footer`:
        return <Footer {...(section as FooterSection)} />;
      case `section-gallery`:
        return <Gallery {...(section as GallerySection)} />;
      case `section-group`:
        return <Group {...(section as GroupSection)} />;
      case `section-header`:
        return <Header {...(section as HeaderSection)} />;
      case `section-headline`:
        return <Headline {...(section as HeadlineSection)} />;
      case `section-hero`:
        return <Hero {...(section as HeroSection)} />;
      case `section-links`:
        return <Links {...(section as LinksSection)} />;
      case `section-logo`:
        return <Logo {...(section as LogoSection)} />;
      default:
        return false;
    }
  })();

  if (!renderedSection) {
    return <></>;
  }
  const { className, ...elementAttributes } = getElementAttributes(attributes);
  const contents = (() => {
    if (ignoreContainer || noContainer) {
      return renderedSection;
    }
    return <div className="container">{renderedSection}</div>;
  })();
  const elementProps = {
    className: clsx(`contentful-section`, `contentful-${type}`, className),
    ...elementAttributes,
  };
  if (noneRevealable.includes(type)) {
    return <div {...elementProps}>{contents}</div>;
  }
  return (
    <Reveal element="section" {...elementProps}>
      {contents}
    </Reveal>
  );
};

/**
 * Sections
 */
export const Sections: FC<SectionsProps> = ({
  children,
  ignoreContainer,
  sections,
}) => (
  <>
    {sections.map((section, index) => (
      <Section
        key={`${section.id}-${index}`}
        ignoreContainer={ignoreContainer}
        {...section}
      >
        {children}
      </Section>
    ))}
  </>
);

export default Section;
